import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BrandService {
    constructor(private http: HttpClient, private api: GlobalService) {}
    getBrands(page = 0, type = 'device', status, limit) {
        const query = `brands?limit=${limit}&offset=${page}&type=${type}&status=${status}`;
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }

    getBrand(id) {
        const query = `brands/${id}`;
        return this.http.get(this.api.endpoint + query, {
            headers: this.api.headers
        });
    }

    updateBrand(id, data) {
        const query = `brands/${id}`;
        return this.http.put(this.api.endpoint + query, data, {
            headers: this.api.headers
        });
    }

    createBrand(data) {
        const query = `brands`;
        return this.http.post(this.api.endpoint + query, data, {
            headers: this.api.headers
        });
    }
}
