import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient, private api: GlobalService) {}

    getUser() {
        const query = 'auth/me';
        return this.http.get(this.api.endpointNotAdmin + query, {
            headers: this.api.headers
        });
    }

    updateUser(data) {
        const query = `auth/me`;
        return this.http.put(this.api.endpointNotAdmin + query, data, {
            headers: this.api.headers
        });
    }

    changePassword(data) {
        const query = `auth/me/change-password`;
        return this.http.post(this.api.endpointNotAdmin + query, data, {
            headers: this.api.headers
        });
    }
}
