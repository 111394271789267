import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class CrossComponentService {
    // Observable navItem source
    private userSource = new BehaviorSubject<{}>({});

    // Observable navItem stream
    users = this.userSource.asObservable();

    constructor(private userService: UserService) {
        this.userService.getUser().subscribe(user => {
            this.userSource.next(user);
        });
    }
    // service command
    updateUser(user: {}) {
        this.userSource.next(user);
    }
}
