import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    constructor(private http: HttpClient, private api: GlobalService) {}
    private url = this.api.endpointNotAdmin;

    upload(files: FileList, type = 'any') {
        const data = new FormData();
        for (let i = 0; i <= files.length; i++) {
            data.append('files', files[i]);
        }
        data.append('type', type);

        return this.http.post(this.url + 'uploads/images', data, {
            headers: this.api.headers
        });
    }
}
